@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.audio-wrap {
  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $purple;
  }
  .add-btn {
    @include wh(18px, 24px);
    @include img("../../assets/add_btn_n.png");
  }
  .delBtn {
    @include wh(18px, 24px);
    @include img("../../assets/del_btn_n.png");
  }
}
.dialog-content {
  .step3 {
    height: 45vh;
    overflow-y: scroll;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    .item {
      position: relative;
      margin-bottom: 40px;
      margin-right: 20px;
      width: 198px;
      cursor: pointer;
      .right {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 2;
      }
      .item-message {
        font-size: 14px;
        .row1 {
          margin-top: 10px;
        }
        .row2 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .step4 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
